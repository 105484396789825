import { createRouter, createWebHashHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import(/* webpackChunkName: "deposit" */ '../components/DepositWidget.vue'),
    props: route => ({ walletAddress: route.query.walletAddress, fiatType: route.query.fiatType, token: route.query.token, xummToken: route.query.xummToken })
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import(/* webpackChunkName: "deposit" */ '../components/WithdrawalWidget.vue'),
    props: route => ({ walletAddress: route.query.walletAddress, fiatType: route.query.fiatType, token: route.query.token, xummToken: route.query.xummToken })
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
