<template>
  <h6 v-if="isSandbox" style="color: red; margin-top: 0;">SANDBOX MODE</h6>
  <router-view v-if="!error"/>
  <div v-else>
    Please close this xApp
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false
    }
  },
  computed: {
    isSandbox() {
      return this.$store.getters.isSandbox
    }
  },
  methods: {
    alert(msg) {
      alert(msg)
    },
    async getToken(code) {
      const body = JSON.stringify({
        client_id: process.env.VUE_APP_GATEHUB_KEY,
        code: code,
        code_verifier: JSON.parse(localStorage.getItem('challenge')).code_verifier,
        grant_type: 'authorization_code'
      })

      const res = await fetch(`https://api.${this.isSandbox ? 'sandbox.' : ''}gatehub.net/auth/v2/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      })
      if(res.status < 200 || res.status > 299) throw res.status

      const json = await res.json()
      return json
    }
  },
  async mounted() {
    this.$store.dispatch('isLoadingState', true)
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const ott = urlParams.get('xAppToken')
      const data = await this.$store.dispatch('getTokenData', ott)

      if(data.ott?.nodetype !== 'MAINNET') {
          this.$store.dispatch('isLoadingState', false)
          this.error = true
          this.alert('Please use this xApp with mainnet only, change nodetype in XUMM')
          window.xapp.close()
          return 'Not Mainnet'
      }
      
      const origin = data?.ott?.origin?.data?.url || data?.ott?.origin?.data?.content
      if(origin) {
        try {
          const url = new URL(origin)
          const searchParams = url.searchParams

          // GateHub removed this in the production, it doe not return login, only in sandbox it returns login
          // && searchParams.has('login')
          if(searchParams.has('code')) {
            const data = await this.getToken(searchParams.get('code'))
            this.$store.dispatch('setAccessToken', data)
          }
        } catch(e) {
          console.log('getting token data error', e)
          this.alert(e)
        }
      }
      await this.$store.dispatch('onboardingCheck')
    } catch(e) {
      console.log('getting token data error', e)
      this.alert(e)
    }
    this.$store.dispatch('isLoadingState', false)
  }
}
</script>

<style>
html, body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: rgb(10, 108, 241); */
}
.iframe-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
iframe {
  border: none;
  height: 100%;
}
</style>
