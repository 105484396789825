import { createStore } from 'vuex'

export default createStore({
  state: {
    token: null,
    userData: null,
    tcAccept: false,
    loading: true
  },
  getters: {
    getAccessToken: state => {
      // access_token, expires_in, scope, token_type
      try {
        if(state.token?.access_token) return state.token?.access_token
      
        const token = localStorage.getItem('token')
        const expires = localStorage.getItem('token_expires_at')
        if(token && expires) {
          if(new Date() < new Date(expires)) return JSON.parse(token)?.access_token
        }
      } catch(e) {
        return null
      }

      return null
    },
    getUserData: state => {
      return state.userData
    },
    fiatDefault: state => {
      return state.userData?.ott?.currency
    },
    walletAddress: state => {
      return state.userData?.ott?.account
    },
    xAppJWT: state => {
      return state.userData?.jwt
    },
    tcAccepted: state => {
      return state.tcAccept
    },
    isLoading: state => {
      return state.loading
    },
    isSandbox: () => {
      return process?.env?.VUE_APP_ENV === 'DEV'
    }
  },
  mutations: {
    setUserData: (state, payload) => {
      state.userData = payload
    },
    setAccessToken: (state, token) => {
      state.token = token
      localStorage.setItem('token', JSON.stringify(token))

      let expires = new Date()
      expires.setSeconds(token.expires_in)
      expires.toJSON()
      localStorage.setItem('token_expires_at', expires)
    },
    setAcceptedStatus: (state, bool) => {
      state.tcAccept = bool
    },
    setLoadingState: (state, bool) => {
      state.loading = bool
    }
  },
  actions: {
    isLoadingState: (context, bool) => {
      context.commit('setLoadingState', bool)
    },
    setUserData: (context, payload) => {
      context.commit('setUserData', payload)
    },
    setAccessToken: (context, payload) => {
      context.commit('setAccessToken', payload)

    },
    // Xumm Methods
    getTokenData: async (context, ott) => {
      try {
          const res = await fetch('https://xumm.app/api/v1/xapp-jwt/authorize', {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'x-api-key': process.env.VUE_APP_XAPP_KEY,
                  'x-api-ott': ott
              }
          })
          const data = await res.json()
          context.dispatch('setUserData', data)
          return data
      } catch(e) {
          throw 'Error getting Token Data'
      }
    },
    onboardingCheck: async (context) => {
      try {
        const dataresult = await context.dispatch('getUserDataStore', 'onboarding')
        console.log(JSON.stringify(dataresult))
        console.log('Init value: ', dataresult?.onboarding?.init)
        if( (dataresult?.onboarding?.init !== 'false' || dataresult?.onboarding?.init !== false) && dataresult?.onboarding?.init != undefined) {
          return context.commit('setAcceptedStatus', true)
        }
      } catch (e) {
        throw `Error doing onboarding checks: ${e}`
      }
    },
    getUserDataStore: async (context, key) => {
      try {
        const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/userdata/${key}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${context.getters.xAppJWT}`
          }
        })

        if(res.status < 200 || res.status > 299) throw `${res.status}`
        const body = await res.json()
        return body.data
      } catch(e) {
        console.error('Error getting userData from XUMM', e)
        throw e
      }
    },
    setUserDataStore: async(context, payload) => {
      const key = payload.key
      const payloadJSON = payload.payloadJSON
      try {
        const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/userdata/${key}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${context.getters.xAppJWT}`
            },
            body: JSON.stringify(payloadJSON)
        })

        if(res.status < 200 || res.status > 299) {
            throw `Status: ${res.status}`
        }
        context.commit('setAcceptedStatus', true)
      } catch(e) {
        console.error('Post userData to XUMM:', e)
        throw `Error with setting user data ${e}`
      }
    }
  }
})
